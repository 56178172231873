import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LicenceService {

    constructor(private _http : HttpClient){ }

    getLicenceInfo(url:string,data:string): Observable<any>{
        //return this._http.post<any>("http://localhost:8888/HunterRest/phprest/api/v1/api_getLicenceProfits.php", data);
        return this._http.post<any>(url, data);
    }

    getProfitsLicence(url:string,data:string): Observable<any>{
        //return this._http.post<any>("http://localhost:8888/HunterRest/phprest/api/v1/api_getLicenceProfits.php", data);
        return this._http.post<any>(url, data);
    }

    getProfitsLicencePerMonth(url: string, data: string) {
        return this._http.post<any>(url, data);
    }
}
