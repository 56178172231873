import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../service/app.config.service';
import { AppConfig } from '../../api/appconfig';
import {LicenceService} from "../../service/licence.service";
import {Licence} from "../../models/Licence";
import {Profit} from "../../models/Profit";
import {LicenceBean} from "../../models/licence-bean";

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {ActivatedRoute} from "@angular/router";
import {AccountService} from "../../service/account.service";

@Component({
  selector: 'app-dashboard-account',
  templateUrl: './dashboard-account.component.html',
  //styleUrls: ['./dashboard-account.component.scss']
})
export class DashboardAccountComponent implements OnInit {


    chartData: any;

    chartOptions: any;

    subscription: Subscription;

    config: AppConfig;


    barData: any;

    barOptions: any;

    data_da: Date;
    data_a: Date;

    platform: string;

    root;

    chart;

    apiCoinsSold = 0.0;
    apiCardsSold = 0.0;

    licence_id: number = undefined;
    email: string = undefined;
    constructor(private productService: ProductService, public configService: ConfigService, private accountService: AccountService,private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.data_da = new Date();
        this.data_da.setDate(this.data_da.getDate()-14);
        this.data_a = new Date();

        this.licence_id = this.route.snapshot.params.licence_id;
        this.email = this.route.snapshot.params.email;

        if(this.licence_id!==undefined && this.email!==undefined){

            let s= {
                "licenceId" : this.licence_id,
                "account" : this.email,
                "data_from" : this.convertDateToString(this.data_da),
                "data_to" : this.convertDateToString(this.data_a),
            };

            this.accountService.getAccountInfo("https://www.futhunter.com/HunterRest/phprest/api/v1/api_getAccountInfo.php",JSON.stringify(s))
                .subscribe(result => {
                    console.log("---"+JSON.stringify(result));
                    let profits_account:Profit[] = result.profit_account.records;
                    let api_info:[] = result.api_info.records;
                    if(api_info!==undefined && api_info.length>0){
                        // @ts-ignore
                        let el = api_info[0];
                        // @ts-ignore
                        this.apiCoinsSold = el.API_CoinsSold;
                        // @ts-ignore
                        this.apiCardsSold = el.API_CardsSold;
                    }
                    profits_account.forEach( value => {
                        this.platform = value.Platform;
                        value.data=new Date(value.data);
                    } );

                    console.log("***"+JSON.stringify(profits_account));
                    //this.createAmChartV2(profits_account);
                    this.createAmBarChartV2(profits_account);

                });
        }
    }

    private createAmBarChartV2(profits: Profit[]){
        /* Chart code */

        this.root = am5.Root.new("chartdiv");

        //let x=am5.Root.new("chartdiv");



        this.root.setThemes([
            am5themes_Animated.new(this.root)
        ]);


        let chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX"
        }));

        let cursor = chart.set("cursor", am5xy.XYCursor.new(this.root, {}));
        cursor.lineY.set("visible", false);


        let xRenderer = am5xy.AxisRendererX.new(this.root, { minGridDistance: 30 });
        xRenderer.labels.template.setAll({
            rotation: -90,
            centerY: am5.p50,
            centerX: am5.p100,
            paddingRight: 15
        });

        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(this.root, {
            maxDeviation: 0.3,
            categoryField: "data",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(this.root, {})
        }));

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
            maxDeviation: 0.3,
            renderer: am5xy.AxisRendererY.new(this.root, {})
        }));


        let series = chart.series.push(am5xy.ColumnSeries.new(this.root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "Profitto",
            sequencedInterpolation: true,
            categoryXField: "data",
            tooltip: am5.Tooltip.new(this.root, {
                labelText:"{valueY}"
            })
        }));

        series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
        series.columns.template.adapters.add("fill", function(fill, target) {
            // @ts-ignore
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.columns.template.adapters.add("stroke", function(stroke, target) {
            // @ts-ignore
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        let data = [];
        for(var pf of profits){

            let d = {
                "data" : pf.data,
                "Profitto" : parseFloat(pf.Profitto+""),
            }
            data.push(d);
        }
        xAxis.data.setAll(data);
        series.data.setAll(data);


// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        chart.appear(1000, 100);


    }


    cerca() {

        if(this.licence_id!==undefined && this.email!==undefined){
            var myobj = document.getElementById("chartdiv");
            myobj.remove();

            const para = document.createElement("div");
            para.id="chartdiv";
            para.style.width="100%";
            para.style.height="400px";

            document.getElementById("parentchart").appendChild(para);
            let s= {
                "licenceId" : this.licence_id,
                "account" : this.email,
                "data_from" : this.convertDateToString(this.data_da),
                "data_to" : this.convertDateToString(this.data_a),
            };

            this.accountService.getAccountInfo("https://www.futhunter.com/HunterRest/phprest/api/v1/api_getAccountInfo.php",JSON.stringify(s))
                .subscribe(result => {
                    console.log("---"+JSON.stringify(result));
                    let profits_account:Profit[] = result.profit_account.records;
                    let api_info:[] = result.api_info.records;
                    if(api_info!==undefined && api_info.length>0){
                        // @ts-ignore
                        let el = api_info[0];
                        // @ts-ignore
                        this.apiCoinsSold = el.API_CoinsSold;
                        // @ts-ignore
                        this.apiCardsSold = el.API_CardsSold;
                    }
                    profits_account.forEach( value => {
                        this.platform = value.Platform;
                        value.data=new Date(value.data);
                    } );

                    console.log("***"+JSON.stringify(profits_account));
                    //this.createAmChartV2(profits_account);
                    this.createAmBarChartV2(profits_account);

                });
        }
    }

    private convertDateToString(data: Date){
        let s_date=null;
        if(data!=null){
            s_date = data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate();
        }
        return s_date;
    }
}
