import {Profit} from "./Profit";
import {array} from "@amcharts/amcharts5";
import {TransazioneLicenza} from "./transazione-licenza";
import {Player} from "./player";

export class LicenceBean {
    public licenceId: number;
    public profit: Profit[]=new Array();
    public coins_last_14_days:number=0;
    public daily_profit:number=0;
    public avg_profit_for_accounts:number=0;
    public daily_captcha:number=0;
    public coins_captcha_wallet:number=0;
    public coins_sbc_wallet:number=0;

    public best_filters: Player[]=new Array();

    public transazioni_licenza: TransazioneLicenza[]=new Array();
}
