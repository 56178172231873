import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    constructor(private _http : HttpClient){ }

    getAccountInfo(url:string,data:string): Observable<any>{
        //return this._http.post<any>("http://localhost:8888/HunterRest/phprest/api/v1/api_getLicenceProfits.php", data);
        return this._http.post<any>(url, data);
    }
}
