<div class="grid">
    <div class="col-12 lg:col-6 xl:col-3">
        <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block text-500 font-medium mb-3">Api Coins Sold</span>
                    <div class="text-900 font-medium text-xl">{{apiCoinsSold | number : '.0'}}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                    <i class="pi pi-money-bill text-blue-500 text-xl"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
        <!--
        <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block text-500 font-medium mb-3">Daily Profits</span>
                    <div class="text-900 font-medium text-xl">{{licence.daily_profit  | number: '.0'}}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-orange-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                    <i class="pi pi-wallet text-orange-500 text-xl"></i>
                </div>
            </div>
        </div>
        -->
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
        <!--
        <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block text-500 font-medium mb-3">Captcha Wallet / Hunter Coins</span>
                    <div class="text-900 font-medium text-xl">{{licence.coins_captcha_wallet | currency : 'EUR'}} / {{licence.coins_sbc_wallet | number : '.'}}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                    <i class="pi pi-euro text-cyan-500 text-xl"></i>
                </div>
            </div>
        </div>
        -->
    </div>

    <div class="col-12 lg:col-6 xl:col-3">

        <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block text-500 font-medium mb-3">Api Card Sold</span>
                    <div class="text-900 font-medium text-xl">{{apiCardsSold}}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-purple-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                    <i class="pi pi-stop-circle text-purple-500 text-xl"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<p-accordion>
<p-accordionTab header="Parameters" [selected]="true">
    <p-fieldset legend="Interval Date">
        <div class="card">
            <div class="formgrid grid">
                <div class="field col-12 md:col-3">
                    <h4>Date from</h4>
                    <p-calendar inputId="basic" dateFormat="dd/mm/yy" [(ngModel)]="data_da"></p-calendar>
                </div>
                <div class="field col-12 md:col-3">
                    <h4>Date to</h4>
                    <p-calendar inputId="basic" dateFormat="dd/mm/yy" [(ngModel)]="data_a"></p-calendar>
                </div>
                <div class="field col-12 md:col-3">
                </div>

                <div class="field col-12 md:col-3">
                    <h4>&nbsp;</h4>
                    <p-button label="Search" icon="pi pi-search" (click)="cerca()"></p-button>
                </div>
            </div>
        </div>
    </p-fieldset>
</p-accordionTab>
</p-accordion>


<div class="grid">

    <div class="col-12 xl:col-12">
        <div id="parentchart" class="card">
            <h5>Bar Chart {{platform}}</h5>
            <div id="chartdiv" style="width: 100%; height: 400px"></div>
        </div>
    </div>

</div>

