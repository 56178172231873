import { Component, OnInit } from '@angular/core';
import {ConfigService} from "../../service/app.config.service";
import {AccountService} from "../../service/account.service";
import {ActivatedRoute} from "@angular/router";
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-best-filter',
  templateUrl: './best-filter.component.html',
  styleUrls: ['./best-filter.component.scss']
})
export class BestFilterComponent implements OnInit {
    data_da: Date;
    data_a: Date;
    licence_id: number = undefined;
    email: string = undefined;

    bestFilters : [];

    cols: any[];

    exportColumns: any[];

  constructor(public configService: ConfigService, private accountService: AccountService,private route: ActivatedRoute) { }

  ngOnInit(): void {

      this.bestFilters = [];
      this.data_da = new Date();
      this.data_da.setDate(this.data_da.getDate());
      this.data_a = new Date();

      this.licence_id = this.route.snapshot.params.licence_id;
      this.email = this.route.snapshot.params.email;

      /*
      if(this.licence_id!==undefined && this.email!==undefined){

          let s= {
              "licenceId" : this.licence_id,
              "account" : this.email,
              "data_from" : this.convertDateToString(this.data_da),
              "data_to" : this.convertDateToString(this.data_a),
          };

          this.accountService.getAccountInfo("https://www.futhunter.com/HunterRest/phprest/api/v1/api_getBestFilters.php",JSON.stringify(s))
              .subscribe(result => {

                  this.bestFilters = result.best_filters.records;


              });
      }
        */
      this.cols = [
          { field: 'code', header: 'Code', customExportHeader: 'Product Code' },
          { field: 'name', header: 'Name' },
          { field: 'category', header: 'Category' },
          { field: 'quantity', header: 'Quantity' }
      ];

      this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

    cerca() {

      this.bestFilters=[];
        if(this.licence_id!==undefined){

            let s= {
                "licenceId" : this.licence_id,
                "account" : this.email,
                "data_from" : this.convertDateToString(this.data_da),
                "data_to" : this.convertDateToString(this.data_a),
            };

            this.accountService.getAccountInfo("https://www.futhunter.com/HunterRest/phprest/api/v1/api_getBestFilters.php",JSON.stringify(s))
                .subscribe(result => {
                    console.log("---"+JSON.stringify(result));
                    this.bestFilters = result.best_filters.records;
                    console.log(this.bestFilters);

                });
        }
    }

    private convertDateToString(data: Date){
        let s_date=null;
        if(data!=null){
            s_date = data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate();
        }
        return s_date;
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            let listToExport = [];


            const worksheet = xlsx.utils.json_to_sheet(this.bestFilters);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "BestFILTERS");
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });

        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

}
