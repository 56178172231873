    <div class="grid">
        <div class="col-12 lg:col-6 xl:col-3">
            <div class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium mb-3">Coins gained last 30 days</span>
                        <div class="text-900 font-medium text-xl">{{licence.coins_last_14_days | number : '.0'}}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-blue-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                        <i class="pi pi-money-bill text-blue-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-3">
            <div class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium mb-3">Daily Profits</span>
                        <div class="text-900 font-medium text-xl">{{licence.daily_profit  | number: '.0'}}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-orange-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                        <i class="pi pi-wallet text-orange-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-3">
            <div class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium mb-3">Captcha Wallet / Hunter Coins</span>
                        <div class="text-900 font-medium text-xl">{{licence.coins_captcha_wallet | currency : 'EUR'}} / {{licence.coins_sbc_wallet | number : '.'}}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                        <i class="pi pi-euro text-cyan-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-3">
            <div class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium mb-3">Daily Captcha</span>
                        <div class="text-900 font-medium text-xl">{{licence.daily_captcha!==undefined && licence.daily_captcha!=null ? licence.daily_captcha : '---'}}</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-purple-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                        <i class="pi pi-stop-circle text-purple-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-3">
            <div class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium mb-3">Running</span>
                        <div class="text-900 font-medium text-xl">{{running}}</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-3">
            <div class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium mb-3">Stuck</span>
                        <div class="text-900 font-medium text-xl">{{stuck}}</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-3">
            <div class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium mb-3">Paused</span>
                        <div class="text-900 font-medium text-xl">{{paused}}</div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-12 lg:col-6 xl:col-3">

            <div class="card mb-0">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="block text-500 font-medium mb-3">Not Logged</span>
                        <div class="text-900 font-medium text-xl">{{notLogged}}</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-12 xl:col-12">
            <div class="card">
                <h5>Stacked Graph last 14 days</h5>
                <div id="chartdiv" style="width: 100%; height: 400px"></div>
            </div>
        </div>
        <div class="col-12 xl:col-6">
            <div class="card">
                <h5>Daily Best Filters</h5>
                <p-table [value]="licence.best_filters" [paginator]="true" [rows]="5" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Image</th>
                            <th pSortableColumn="PlayerName">Name <p-sortIcon field="PlayerName"></p-sortIcon></th>
                            <th>Profit</th>
                            <th>Platform</th>
                            <th>Rarity</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-player>
                        <tr>
                            <td style="width: 15%; min-width: 5rem;">
                                <img src="https://futmind.com/images/fifa2022/players/{{player.PlayerBaseId}}.png" class="shadow-4" alt="" width="50">
                                <!-- <img *ngIf="player.Rarity=='Rare' || player.Rarity==''" src="https://futmind.com/images/fifa2022/players/{{player.PlayerBaseId}}.png" class="shadow-4" alt="" width="50">
                                <img *ngIf="player.Rarity!='Rare' && player.Rarity!=''" src="https://futmind.com/images/fifa2022/players/p{{player.ResourceId}}.png" class="shadow-4" alt="" width="50"> -->
                            </td>
                            <td style="width: 35%; min-width: 7rem;">{{player.PlayerName}}</td>
                            <td style="width: 35%; min-width: 8rem;">{{player.Profit | number:'.0'}}</td>
                            <td style="width: 35%; min-width: 7rem;">{{player.Platform}}</td>
                            <td style="width: 35%; min-width: 7rem;">{{player.Rarity}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>



        </div>


        <div class="col-12 xl:col-6">
            <div class="card">
                <h5>Bar Chart</h5>
                <div id="bar_chart" style="width: 100%; height: 520px"></div>
            </div>


        </div>
        <div class="col-12 xl:col-12">
        <div class="card">
            <div class="flex align-items-center justify-content-between mb-4">
                <h5>Last Trasactions</h5>
                <div>
                    <!-- <button pButton type="button" icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text p-button-plain" (click)="menu.toggle($event)"></button>
                      <p-menu #menu [popup]="true" [model]="items"></p-menu>-->
                </div>
            </div>

            <span class="block text-600 font-medium mb-3"></span>
            <ul class="p-0 mx-0 mt-0 mb-4 list-none">
                <li *ngFor="let tr of licence.transazioni_licenza " class="flex align-items-center py-2 border-bottom-1 surface-border">
                    <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                        <i class="pi pi-dollar text-xl text-blue-500"></i>
                    </div>
                    <span class="text-900 line-height-3">{{tr.Prodotto}}
                        <span class="text-700">  <span class="text-blue-500">{{tr.Importo}}€</span></span> </span>
                </li>
            </ul>
        </div>
            </div>
    </div>

