import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../service/app.config.service';
import { AppConfig } from '../../api/appconfig';
import {LicenceService} from "../../service/licence.service";
import {Licence} from "../../models/Licence";
import {Profit} from "../../models/Profit";
import {LicenceBean} from "../../models/licence-bean";

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {ActivatedRoute} from "@angular/router";
@Component({
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {

    items: MenuItem[];

    products: Product[];

    chartData: any;

    chartOptions: any;

    subscription: Subscription;

    config: AppConfig;

    licence: LicenceBean=new LicenceBean();

    lastDays: number=14;

    barData: any;

    barOptions: any;

    notLogged = "0";
    running = "0";
    paused = "0";
    stuck = "0";

    licence_id: number = undefined;
    constructor(private productService: ProductService, public configService: ConfigService, private licenceService: LicenceService,private route: ActivatedRoute) {
    }

    ngOnInit() {

        this.licence_id = this.route.snapshot.params.licence_id;

        if(this.licence_id!==undefined){
            this.config = this.configService.config;
            this.subscription = this.configService.configUpdate$.subscribe(config => {
                this.config = config;
                this.updateChartOptions();
            });
            this.productService.getProductsSmall().then(data => this.products = data);

            this.items = [
                {label: 'Add New', icon: 'pi pi-fw pi-plus'},
                {label: 'Remove', icon: 'pi pi-fw pi-minus'}
            ];
            let s= {
                "licenceId" : this.licence_id,
                "lastDays" : "14"
            };

            this.licenceService.getLicenceInfo("https://www.futhunter.com/HunterRest/phprest/api/v1/api_getLicenceInfo.php",JSON.stringify(s))
                .subscribe(result => {
                    let profits_licence:Profit[] = result.profit_licence.records;
                    let status:[] = result.status.records;

                    if(status!==undefined && status.length>0){
                        for(let i=0; i<status.length;++i){
                            let el = status[i];
                            // @ts-ignore
                            if(el.AccountStatus=="NotLogged"){
                                // @ts-ignore
                                this.notLogged=el.NumAccounts;
                            }
                            // @ts-ignore
                            if(el.AccountStatus=="Running"){
                                // @ts-ignore
                                this.running=el.NumAccounts;
                            }
                            // @ts-ignore
                            if(el.AccountStatus=="Stuck"){
                                // @ts-ignore
                                this.stuck=el.NumAccounts;
                            }
                            // @ts-ignore
                            if(el.AccountStatus=="Paused"){
                                // @ts-ignore
                                this.paused=el.NumAccounts;
                            }
                        }
                    }
                    profits_licence.forEach( value => {
                        value.data=new Date(value.data);
                        this.licence.coins_last_14_days= this.licence.coins_last_14_days+ parseFloat(value.profit_ps4+"")+parseFloat(value.profit_xbox+"")+parseFloat(value.profit_pc+"");
                    } );
                    this.createAmChartV2(profits_licence);

                    let profits_per_month:Profit[] = result.profit_licence_for_month.records;
                    this.createAmBarChart(profits_per_month);

                    this.licence.transazioni_licenza = result.transazioni_licenza.records;
                    this.licence.daily_profit = result.daily_profit.profits;
                    if(this.licence.daily_profit!==undefined && this.licence.daily_profit>0) this.licence.daily_profit = parseInt(this.licence.daily_profit+""); else this.licence.daily_profit=0;
                    this.licence.daily_captcha = result.daily_captcha_images.immagini;

                    this.licence.coins_captcha_wallet = result.coins_captcha_wallet.Wallet!=null && result.coins_captcha_wallet.Wallet!==undefined ? result.coins_captcha_wallet.Wallet : 0.0;
                    this.licence.coins_sbc_wallet = result.coins_sbc_wallet.WalletSBC!=null && result.coins_sbc_wallet.WalletSBC!==undefined ? result.coins_sbc_wallet.WalletSBC : 0.0;
                    this.licence.best_filters=result.best_filters.records;
                });
        }
    }

    updateChartOptions() {
        if (this.config.dark)
            this.applyDarkTheme();
        else
            this.applyLightTheme();

        this.applyLightTheme();

    }

    applyDarkTheme() {
        this.chartOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color:  'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color:  'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };
    }

    applyLightTheme() {
            this.chartOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color:  '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color:  '#ebedef',
                    }
                },
            }
        };
    }

    private createAmChartV2(profits: Profit[]) {

        let root = am5.Root.new("chartdiv");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);


        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX"
        }));


        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);

        let data = [];
        for(var pf of profits){

            let d = {
                //"data" : pf.data.toISOString().substring(0, 10)+"",
                "data" : pf.data,
                "profit_ps4" : parseFloat(pf.profit_ps4+""),
                "profit_xbox" : parseFloat(pf.profit_xbox+""),
                "profit_pc" : parseFloat(pf.profit_pc+"")
            }
            data.push(d);
        }

// Create axes
// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "data",
            startLocation: 0.5,
            endLocation: 0.5,
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.data.setAll(data);

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        }));

// Add series
// https://www.amcharts.com/docs/v5/charts/xy-chart/series/

        function createSeries(name, field) {
            let series = chart.series.push(am5xy.LineSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                stacked:true,
                valueYField: field,
                fill : field=='profit_ps4' ? am5.color("#01008d") : field=='profit_xbox' ? am5.color("#00de67") : am5.color("#cc0001"),
                categoryXField: "data",
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "[bold]{name}[/]\n{categoryX}: {valueY}"
                })
            }));

            series.fills.template.setAll({
                fillOpacity: 0.5,
                visible: true
            });

            series.data.setAll(data);
            series.appear(1000);
        }



        createSeries("Pc", "profit_pc");
        createSeries("Xbox", "profit_xbox");
        createSeries("Ps4", "profit_ps4");

// Add scrollbar
// https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal"
        }));

// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);


    }

    private createAmBarChart(profits: Profit[]) {
        let root = am5.Root.new("bar_chart");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));

        let legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50
            })
        );

        let data = [];
        for(var pf of profits){

            let d = {
                //"data" : pf.data.toISOString().substring(0, 10)+"",
                "year" : pf.year+"-"+pf.month,
                "profit_ps4" : parseFloat(pf.profit_ps4+""),
                "profit_xbox" : parseFloat(pf.profit_xbox+""),
                "profit_pc" : parseFloat(pf.profit_pc+"")
            }
            data.push(d);
        }

// Create axes
// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "year",
            renderer: am5xy.AxisRendererX.new(root, {
                cellStartLocation: 0.1,
                cellEndLocation: 0.9
            }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.data.setAll(data);

        let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
        }));


// Add series
// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function makeSeries(name, fieldName) {
            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: name,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: fieldName,
                fill : fieldName=='profit_ps4' ? am5.color("#00008e") : fieldName=='profit_xbox' ? am5.color("#008a00") : am5.color("#cc0001"),
                categoryXField: "year"
            }));

            series.columns.template.setAll({
                tooltipText: "{name}, {categoryX}  :  {valueY}",
                width: am5.percent(90),
                tooltipY: 0
            });

            series.data.setAll(data);

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true
                    })
                });
            });

            legend.data.push(series);
        }

        makeSeries("Ps4", "profit_ps4");
        makeSeries("Xbox", "profit_xbox");
        makeSeries("Pc", "profit_pc");


// Make stuff animate on load
// https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);

    }

}
