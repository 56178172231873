import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EmptyComponent } from './components/empty/empty.component';
import { AppMainComponent } from './app.main.component';
import { ErrorComponent } from './components/error/error.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { AccessComponent } from './components/access/access.component';
import {DashboardAccountComponent} from "./components/dashboard-account/dashboard-account.component";
import {BestFilterComponent} from "./components/best-filter/best-filter.component";
@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {path: ':licence_id', component: DashboardComponent},
                    {path: ':licence_id/:email', component: DashboardAccountComponent},
                    {path: ':licence_id/:email/:param', component: BestFilterComponent},
                    {path: 'hidden/bestFilters/:licence_id', component: BestFilterComponent},
                    {path: 'pages/empty', component: EmptyComponent}
                ],
            },
            {path:'pages/error', component: ErrorComponent},
            {path:'pages/notfound', component: NotfoundComponent},
            {path:'pages/access', component: AccessComponent},
            {path: '**', redirectTo: 'pages/notfound'},

        ], {scrollPositionRestoration: 'enabled', anchorScrolling:'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
