<p-accordion>
    <p-accordionTab header="Parameters" [selected]="true">
        <p-fieldset legend="Interval Date">
            <div class="card">
                <div class="formgrid grid">
                    <div class="field col-12 md:col-3">
                        <h4>Date from</h4>
                        <p-calendar inputId="basic" dateFormat="dd/mm/yy" [(ngModel)]="data_da"></p-calendar>
                    </div>
                    <div class="field col-12 md:col-3">
                        <h4>Date to</h4>
                        <p-calendar inputId="basic" dateFormat="dd/mm/yy" [(ngModel)]="data_a"></p-calendar>
                    </div>
                    <div class="field col-12 md:col-3">
                    </div>

                    <div class="field col-12 md:col-3">
                        <h4>&nbsp;</h4>
                        <p-button label="Search" icon="pi pi-search" (click)="cerca()"></p-button>
                    </div>
                </div>
            </div>
        </p-fieldset>
    </p-accordionTab>
</p-accordion>

<div class="grid">
    <div class="col-12 xl:col-12">
        <div class="card">
            <h5>Daily Best Filters</h5>
            <p-table [value]="bestFilters" [paginator]="true" [rows]="10" responsiveLayout="scroll">
                <ng-template pTemplate="caption">
                    <div class="flex">
                        <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Date</th>
                        <th>Rarity</th>
                        <th>Quality</th>
                        <th>Position</th>
                        <th>Profit</th>
                        <th>NumLicences</th>
                        <th>NumAccount</th>
                        <th>AvgProfit Per Account</th>
                        <th>NumBought</th>
                        <th>Avg_BuyPrice</th>
                        <th>Avg_SellPrice</th>
                        <th>Max_SellPrice</th>
                        <th>FilterSettings_String</th>
                        <th>FilterSettings</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-player>
                    <tr>

                        <td style="width: 10%; min-width: 7rem;">{{player.Date}}</td>
                        <td style="width: 10%; min-width: 7rem;">{{player.Rarity}}</td>
                        <td style="width: 10%; min-width: 7rem;">{{player.Quality}}</td>
                        <td style="width: 10%; min-width: 7rem;">{{player.Position}}</td>
                        <td style="width: 10%; min-width: 7rem;">{{player.Profit}}</td>
                        <td style="width: 10%; min-width: 7rem;">{{player.NumLicences}}</td>
                        <td style="width: 10%; min-width: 7rem;">{{player.NumAccount}}</td>
                        <td style="width: 10%; min-width: 7rem;">{{player.AvgProfitPerAccount}}</td>
                        <td style="width: 10%; min-width: 7rem;">{{player.NumBought}}</td>
                        <td style="width: 10%; min-width: 7rem;">{{player.Avg_BuyPrice}}</td>
                        <td style="width: 10%; min-width: 7rem;">{{player.Avg_SellPrice}}</td>
                        <td style="width: 10%; min-width: 7rem;">{{player.Max_SellPrice}}</td>
                        <td style="width: 80%; min-width: 40rem;">{{player.FilterSettings_String}}</td>
                        <td style="width: 80%; min-width: 40rem;">{{player.FilterSettings}}</td>


                    </tr>
                </ng-template>
            </p-table>
        </div>



    </div>

</div>
